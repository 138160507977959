<template>
    <page
        id="glossaries-page"
        :title="title"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <h2 v-if="!!profile" class="text-center">
                <span v-if="!rows.length">{{ $root.translate("Hello {name}!", { name: profile.first_name }) }}</span>
                <span v-else>{{ $root.translate("Hello {name}, here are some words for you to practice", { name: profile.first_name }) }}:</span>
            </h2>
        </template>

        <div v-if="!rows.length" class="empty">
            <div class="empty-icon">
                <open-icon glyph="frown" size="3x" />
            </div>
            <p class="empty-title">{{ $root.translate("Bummer! You have no glossaries available") }}</p>
            <p class="empty-subtitle h5">{{ $root.translate("Ask your teacher to assign some to you.") }}</p>
        </div>
        <div v-else class="columns semi-responsive">
            <div
                v-for="assignment in rows"
                :key="assignment.id"
                class="column"
            >
                <router-link
                    :to="{ name: 'learn.glossary', params: { id: assignment.asset_id } }"
                >
                    <game-tile
                        :game="asset_by_id(assignment.asset_id)"
                    />
                </router-link>
            </div>
        </div>
    </page>
</template>

<script>
import { mapGetters } from "vuex"

import { is_remote_data_table_source, profile_utilities } from "@/nibnut/mixins"
import { is_page } from "@/custom/mixins"

import { OpenIcon } from "@/nibnut/components"
import { GameTile } from "@/custom/components"

export default {
    name: "Glossaries",
    mixins: [is_page, is_remote_data_table_source, profile_utilities],
    components: {
        OpenIcon,
        GameTile
    },
    methods: {
        pre_load () {
            this.set_state_value("filter", this.constants("asset_types", "TYPE_GLOSSARY").id)
        },
        asset_by_id (id) {
            return this.entity_record("asset", id) || {}
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        title () {
            return this.$root.translate("Glossary")
        },
        fields () {
            return [
                "fieldset::student-list",
                "ns::asset;fieldset::student-list"
            ]
        }
    },
    data () {
        return {
            entity: "assignment",
            entityName: "Glossary:::Glossary",
            relation_ids: ["asset"],

            default_state: {
                per_page: 0,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: "type",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
