<template>
    <page
        id="game-page"
        :is-root="true"
        :title="title"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title><span></span></template>

        <div
            v-if="!error"
            id="game"
        ></div>
        <div
            v-else
            class="empty"
        >
            <div>
                <div class="empty-icon">
                    <open-icon glyph="bomb" size="5x" />
                </div>
                <p class="empty-title h5">{{ $root.translate("Jeepers! That game can't load!") }}</p>
                <div class="empty-action">
                    <router-link
                        :to="{ name: 'learn.home' }"
                        :title="$root.translate('Pick another game')"
                        class="btn btn-primary"
                    >
                        {{ $root.translate("Pick another game") }}
                    </router-link>
                </div>
                <div class="empty-action">
                    <app-logo />
                </div>
            </div>
        </div>
    </page>
</template>

<script>
import { mapGetters } from "vuex"

import { is_page, addl_profile_utilities } from "@/custom/mixins"

import { AppLogo, OpenIcon } from "@/nibnut/components"

let nodes = []

export default {
    name: "Game",
    mixins: [is_page, addl_profile_utilities],
    components: {
        AppLogo,
        OpenIcon
    },
    created () {
        window.addEventListener("beforeunload", this.pause_game)
    },
    mounted () {
        this.load()
    },
    beforeRouteLeave (to, from, next) {
        if(this.is_student) {
            this.pause_game().then(() => {
                next()
            })
        } else next()
    },
    beforeDestroy () {
        this.cleanup()
        window.removeEventListener("beforeunload", this.pause_game)
    },
    watch: {
        $route: "load",
        profile_id: "load"
    },
    methods: {
        load () {
            if(this.is_student && !this.$route.params.id) this.statused(404)
            else if(this.profile_id) {
                this.waiting = true
                this.$store.dispatch(
                    "FETCH_RECORD",
                    {
                        entity: "assignment",
                        id: this.$route.params.id,
                        query: {
                            fields: ["fieldset::play", "ns::asset;fieldset::play"],
                            relation_ids: ["asset"]
                        }
                    }
                ).then(assignment => {
                    this.assignment_id = assignment.id
                    if(!this.is_student) {
                        const grade = parseInt(this.$route.query.grade)
                        assignment.current_level = this.game.starting_levels[(grade >= 0) ? grade : 0]
                    }
                    this.load_game()
                }).catch(error => {
                    console.error(error.message)
                    this.statused(404)
                    this.waiting = false
                })
            }
        },
        cleanup () {
            if(!!window.jeuSamAmuse && !!window.jeuSamAmuse.app) {
                window.SAM_AMUSE_LISTENERS.forEach(({ target, type, callback, options }) => {
                    target.removeEventListener(type, callback, options)
                })
                window.SAM_AMUSE_LISTENERS = []
                EventTarget.prototype.addEventListener = EventTarget.prototype.SAM_AMUSE_NATIVE_ADD_EVENT_LISTENER

                const jeuSamAmuse = window.jeuSamAmuse
                delete window.jeuSamAmuse
                window.PIXI.Ticker.shared.stop()
                /*
                if(jeuSamAmuse.masterLoading && jeuSamAmuse.masterLoading.loader) jeuSamAmuse.masterLoading.loader.destroy()
                if(jeuSamAmuse.soundLoader && jeuSamAmuse.soundLoader.loader) jeuSamAmuse.soundLoader.loader.destroy()
                jeuSamAmuse.app.ticker.stop()
                */
                jeuSamAmuse.app.destroy(true, true)
                window.PIXI = null
                delete window.SAM_AMUSE_CONFIG
                for(var loop = (nodes.length - 1); loop >= 0; loop--) {
                    if(nodes[loop] && nodes[loop].remove) nodes[loop].remove()
                }
                nodes = []
            }
        },
        load_game () {
            this.cleanup()
            const assignment = this.assignment
            const game = this.game
            const base_url = `/storage/games/${game.slug}/`
            window.SAM_AMUSE_CONFIG = {
                l: "www.samamuse.ca", // window.location.hostname
                base_url,
                jeu: game.theme,
                subject: game.topic,
                lang: "fr",
                level: assignment.current_level,
                has_text: assignment.with_text,
                call_api: (action, data) => {
                    // console.log("DEBUG call_api", action, data)

                    const is_student = this.is_student
                    let promise

                    switch (action) {
                    case "start":
                        // data: { question, answer }
                        // upsert question, and start Answer for it ** IF exists a current started answer, delete/reset?
                        if(!is_student) promise = Promise.resolve()
                        else {
                            return this.$store.dispatch(
                                "RECORD_ACTION",
                                {
                                    entity: "assignment",
                                    id: assignment.id,
                                    action: "start",
                                    method: "post",
                                    data
                                }
                            )
                        }
                        break
                    case "repeat":
                        // repeat question for currently started Answer
                        promise = this.repeat_question()
                        break
                    case "pause":
                        // cancel currently started Answer
                        promise = this.pause_game()
                        break
                    case "end":
                        // data: { question, answer }
                        // upsert question, and end current Answer
                        if(!is_student) promise = Promise.resolve()
                        else {
                            return this.$store.dispatch(
                                "RECORD_ACTION",
                                {
                                    entity: "assignment",
                                    id: assignment.id,
                                    action: "end",
                                    method: "put",
                                    data
                                }
                            )
                        }
                        break
                    case "level":
                        // data: { new_level }
                        // store current assignment level, that will override assignment's level when we next start the game
                        if(!is_student) promise = Promise.resolve(data.new_level)
                        else {
                            return this.$store.dispatch(
                                "RECORD_ACTION",
                                {
                                    entity: "assignment",
                                    id: assignment.id,
                                    action: "level",
                                    method: "put",
                                    data
                                }
                            ).then(record => {
                                return record.current_level
                            })
                        }
                        break
                    case "logout":
                        this.pause_game().then(() => {
                            if(!is_student) this.$router.push({ name: "activity.list" })
                            else {
                                this.$store.dispatch("LOGOUT").then(() => {
                                    this.$router.replace({ name: "learn.home" })
                                })
                            }
                        })
                        break
                    }

                    return promise
                }
            }
            window.SAM_AMUSE_CONFIG.datas = game.meta ? (game.meta.data || {}) : {}
            const back_info = this.back_info
            window.SAM_AMUSE_CONFIG.homeurl = (!!back_info && back_info.id) ? back_info.id : "/learn"

            window.SAM_AMUSE_LISTENERS = []
            EventTarget.prototype.SAM_AMUSE_NATIVE_ADD_EVENT_LISTENER = EventTarget.prototype.addEventListener
            EventTarget.prototype.addEventListener = function (type, callback, options) {
                window.SAM_AMUSE_LISTENERS.push({ target: this, type, callback, options })
                this.SAM_AMUSE_NATIVE_ADD_EVENT_LISTENER(type, callback, options)
            }

            const assets = [...((game.meta && game.meta.assets) ? game.meta.assets : [])]
            const load_next = () => {
                if(assets.length) {
                    let url = assets.shift()
                    url = url.match(/^http/i) ? url : `${base_url}${url}`
                    const asset_id = url.replace(/[^a-zA-Z0-9]+/g, "")

                    let node = document.getElementById(asset_id)
                    if(!node) {
                        let src_attribute = "src"
                        if(url.match(/\.js/i)) {
                            node = document.createElement("script")
                            if(url.match(/game\.js/)) {
                                if(window.PIXI && window.PIXI.utils && window.PIXI.utils.skipHello) window.PIXI.utils.skipHello()
                                window.onerror = error => {
                                    this.error = error
                                    this.waiting = false
                                }
                            }
                        } else {
                            node = document.createElement("link")
                            node.setAttribute("rel", "stylesheet")
                            src_attribute = "href"
                            const matches = url.match(/fonts\.googleapis\.com\/css2\?family=([^:?&]+)/)
                            if(matches) {
                                // force font files to load, so canvas has them available on load
                                // I *think* this is only required for dynamic font URLs like Google's
                                // We may eventually decide this makes more sense either as an extracted list of font families upon game installation OR as font family attribute in the game's meta file
                                const font_loader = document.createElement("div")
                                font_loader.style.position = "absolute"
                                font_loader.style.right = "0px"
                                font_loader.style.bottom = "0px"
                                font_loader.style.background = "transparent"
                                font_loader.style.color = "transparent"
                                font_loader.style.pointerEvents = "none"
                                font_loader.style.fontFamily = matches[1]
                                font_loader.textContent = "."
                                document.body.appendChild(font_loader)
                            }
                        }
                        node.addEventListener("load", () => {
                            if(url.match(/game\.js/)) {
                                this.waiting = false
                                setTimeout(() => {
                                    window.onerror = null
                                }, 10000)
                                window.document.dispatchEvent(new Event("DOMContentLoaded", {
                                    bubbles: true,
                                    cancelable: true
                                }))
                            }
                            load_next()
                        })
                        node.setAttribute("id", asset_id)
                        node.setAttribute(src_attribute, url)
                        document.head.appendChild(node)
                        nodes.push(node)
                    } else load_next()
                }
            }
            load_next()
        },
        pause_game () {
            if(!this.is_student || !this.assignment || !this.assignment.id) return Promise.resolve()
            return this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "assignment",
                    id: this.assignment.id,
                    action: "pause",
                    method: "put"
                }
            )
        },
        repeat_question () {
            if(!this.is_student || !this.assignment || !this.assignment.id) return Promise.resolve()
            return this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "assignment",
                    id: this.assignment.id,
                    action: "repeat",
                    method: "put"
                }
            )
        }
    },
    computed: {
        ...mapGetters(["entity_record", "history_back_info"]),
        back_info () {
            return this.history_back_info()
        },
        assignment () {
            return this.entity_record("assignment", this.assignment_id) || {}
        },
        game () {
            const assignment = this.assignment
            if(!assignment || !assignment.asset_id) return {}
            return this.entity_record("asset", assignment.asset_id) || {}
        },
        title () {
            const game = this.game
            if(game) return game.name
            return this.$root.translate("Another Sam Amuse Game")
        }
    },
    data () {
        return {
            assignment_id: 0,
            error: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#game-page {
    #game {
        & > canvas {
            display: block;
            margin: 0 auto;
        }
    }
    .empty {
        height: 100vh;
        padding: 0;

        & > div {
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
        }
        .las, .lab {
            font-size: 5em;
        }
        .app-logo {
            display: inline-block;
            width: 4rem;
        }
    }
}
</style>
