<template>
    <page
        id="student-dashboard-page"
        :title="$root.translate('Home')"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <h2 v-if="!!profile" class="text-center">
                <span v-if="!rows.length">{{ $root.translate("Hello {name}!", { name: profile.first_name }) }}</span>
                <span v-else>{{ $root.translate("Hello {name}, here are your daily games", { name: profile.first_name }) }}:</span>
            </h2>
        </template>

        <div v-if="!rows.length" class="empty">
            <div class="empty-icon">
                <open-icon glyph="sad-tear" size="3x" />
            </div>
            <p class="empty-title">{{ $root.translate("Jeepers! You have no games available") }}</p>
            <p class="empty-subtitle h5">{{ $root.translate("Ask your teacher to assign some to you.") }}</p>
        </div>
        <div v-else class="columns semi-responsive">
            <div
                v-for="assignment in new_assignments"
                :key="assignment.id"
                class="column"
            >
                <router-link
                    :to="{ name: 'learn.game', params: { id: assignment.id } }"
                >
                    <game-tile
                        :game="asset_by_id(assignment.asset_id)"
                    />
                </router-link>
            </div>
        </div>

        <div
            v-if="!!previous_assignments.length"
            class="previous-games"
        >
            <div class="semi-responsive">
                <h3 class="mt-8">{{ $root.translate("Keep playing!") }}</h3>
                <ticker
                    :item-width="previous_game_card_size"
                >
                    <router-link
                        v-for="assignment in previous_assignments"
                        :key="assignment.id"
                        :to="{ name: 'learn.game', params: { id: assignment.id } }"
                    >
                        <game-tile
                            :game="asset_by_id(assignment.asset_id)"
                            :style="{ width: `${previous_game_card_size}px` }"
                        />
                    </router-link>
                </ticker>
            </div>
        </div>
    </page>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { is_remote_data_table_source, profile_utilities } from "@/nibnut/mixins"
import { is_page } from "@/custom/mixins"

import { OpenIcon } from "@/nibnut/components"
import { GameTile, Ticker } from "@/custom/components"

export default {
    name: "StudentDashboard",
    mixins: [is_page, is_remote_data_table_source, profile_utilities],
    components: {
        OpenIcon,
        GameTile,
        Ticker
    },
    methods: {
        pre_load () {
            this.set_state_value("filter", this.constants("asset_types", "TYPE_GAME").id)
        },
        asset_by_id (id) {
            return this.entity_record("asset", id) || {}
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        fields () {
            return [
                "fieldset::student-dashboard",
                "ns::asset;fieldset::student-dashboard"
            ]
        },
        new_assignments () {
            return this.rows.filter(assignment => !assignment.ended_at)
        },
        previous_assignments () {
            return orderBy(this.rows.filter(assignment => !!assignment.ended_at), "ended_at", "desc")
        }
    },
    data () {
        return {
            entity: "assignment",
            entityName: "Assignment:::Assignments",
            relation_ids: ["asset"],

            default_state: {
                per_page: 0,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: "type",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            previous_game_card_size: 330 * 0.9
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#student-dashboard-page {
    .game {
        border: 1px solid $light-color;
        @include shadow-variant(0.015rem);

        & > img {
            display: block;
        }
    }

    .previous-games {
        padding: $unit-4 0 $unit-8 0;
        margin-top: $unit-8;
        background-color: $brand-green;

        h3 {
            color: $light-color;
            text-align: center;
            font-size: 26px;
            margin-bottom: $unit-4;
        }
    }
}
</style>
