<template>
    <page
        id="score-card"
        :title="$root.translate('Score Card')"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <div  class="text-center">
                <app-logo />
                <h2 v-if="!!edited_record">
                    {{ edited_record.name }}
                </h2>
                <h3 class="text-gray">
                    {{ $root.translate("Score Card") }}
                </h3>
            </div>
        </template>

        <div
            v-for="subject in subjects"
            :key="subject.id"
        >
            <h5>
                <span class="dot" :style="{ 'background-color': subject.color }"></span>{{ subject.name }}
            </h5>

            <div v-if="!!edited_record">
                <apexchart
                    :height="200"
                    type="area"
                    :options="chart_options('time', subject)"
                    :series="chart_data('time', subject)"
                />
                <apexchart
                    :height="200"
                    type="area"
                    :options="chart_options('success', subject)"
                    :series="chart_data('success', subject)"
                />
            </div>
        </div>
    </page>
</template>

<script type="text/javascript">
import orderBy from "lodash/orderBy"

import { is_page, is_record_editor } from "@/custom/mixins"

import { AppLogo } from "@/nibnut/components"
import VueApexCharts from "vue-apexcharts"

let timer = null

export default {
    mixins: [is_page, is_record_editor],
    components: {
        AppLogo,
        Apexchart: VueApexCharts
    },
    beforeDestroy () {
        if(timer) {
            clearTimeout(timer)
            timer = null
        }
    },
    methods: {
        post_load () {
            timer = setTimeout(() => {
                window.print()
            }, 500)
        },
        chart_data_by_chart_id (chart_id, subject_id) {
            if(!!this.edited_record && !!this.edited_record.score_card_statistics) {
                const statistics = this.edited_record.score_card_statistics.find(statistics => statistics.subject_tag_id === subject_id)
                if(!!statistics && !!statistics[chart_id]) return statistics[chart_id]
            }
            return {}
        },
        chart_options (chart_id, subject) {
            const data = this.chart_data_by_chart_id(chart_id, subject.id)

            return {
                chart: {
                    id: `${chart_id}-${subject.id}`,
                    height: 200,
                    width: window.innerWidth,
                    type: "area",
                    animations: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                title: {
                    text: (chart_id === "success") ? this.$root.translate("Progression Rate") : this.$root.translate("Game Time")
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    opacity: 0.3
                },
                colors: [subject.color],
                tooltip: {
                    y: {
                        formatter: (value) => {
                            if(chart_id === "success") return `${this.$numeral(value).format("0,0")}%`
                            return `${this.$numeral(value / 60).format("0,0.0")} min`
                        }
                    }
                },
                xaxis: {
                    categories: Object.keys(data).map(date => {
                        return this.$moment(date).format("MM/DD")
                    })
                },
                yaxis: {
                    title: {
                        text: (chart_id === "success") ? "" : this.$root.translate("minutes")
                    },
                    forceNiceScale: true,
                    decimalsInFloats: (chart_id === "success"),
                    labels: {
                        formatter: (value, index) => {
                            if(chart_id === "success") return `${this.$numeral(value).format("0,0")}%`
                            return this.$numeral(value / 60).format("0,0.0")
                        }
                    }
                }
            }
        },
        chart_data (chart_id, subject) {
            const data = this.chart_data_by_chart_id(chart_id, subject.id)

            return [
                {
                    name: (chart_id === "success") ? this.$root.translate("Progression Rate") : this.$root.translate("Game Time"),
                    data: Object.values(data)
                }
            ]
        }
    },
    computed: {
        subjects () {
            const taxonomy = this.constants("taxonomies", "TAXONOMY_SUBJECT").id
            return orderBy(this.entity_records("tag").filter(tag => tag.taxonomy === taxonomy), "name", "asc")
        }
    },
    data () {
        return {
            entity: "user",
            entityName: "Student:::Students",
            fields: ["fieldset::score-card"]
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#score-card {
    width: 7in;
    margin: 0 auto;
    .app-logo {
        display: inline-block;
        height: 9em;
    }
    .page-body > div {
        page-break-inside: avoid;
    }
}
</style>
