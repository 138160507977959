<template>
    <table class="table">
        <tbody>
            <tr v-if="role_is_student(record.role)">
                <td>
                    <div class="text-small text-gray">{{ $root.translate("Birth Date") }}</div>
                    <span :class="{ 'text-strikethrough': isSource, 'text-gray': isSource }">
                        {{ birth_date || $root.translate("Unknown") }}
                    </span>
                </td>
            </tr>
            <tr v-else>
                <td>
                    <div class="text-small text-gray">{{ $root.translate("Email") }}</div>
                    <span :class="{ 'text-strikethrough': isSource, 'text-gray': isSource }">
                        {{ record.email || $root.translate("Unknown") }}
                    </span>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="text-small text-gray">{{ $root.translate("Institution") }}</div>
                    <span :class="{ 'text-strikethrough': isSource, 'text-gray': isSource }">
                        {{ record.institution_name || $root.translate("Unknown") }}
                    </span>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="text-small text-gray">{{ $root.translate("Grade") }}</div>
                    <span :class="{ 'text-strikethrough': isSource, 'text-gray': isSource }">
                        {{ grade_by_id(record.grade).name || $root.translate("Unknown") }}
                    </span>
                </td>
            </tr>
            <tr v-if="!role_is_student(record.role)">
                <td>
                    <div class="text-small text-gray">{{ $root.translate("Address") }}</div>
                    <span :class="{ 'text-strikethrough': isSource, 'text-gray': isSource }">
                        {{ address_one_line(record) || $root.translate("Unknown") }}
                    </span>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="text-small text-gray">{{ $root.translate("Group Membership(s)") }}</div>
                    <div
                        v-for="membership in record.group_memberships_list"
                        :key="membership.id"
                        class="chip mt-2"
                    >
                        <img
                            v-if="!!membership.avatar"
                            :src="membership.avatar"
                            class="avatar avatar-sm"
                        >
                        <span v-if="role_is_student(record.role)">
                            {{ membership.username }} ({{ membership.group_name }})
                        </span>
                        <span v-else>
                            {{ membership.group_name }}
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { string_utilities } from "@/nibnut/mixins"
import { addl_profile_utilities, handles_grades } from "@/custom/mixins"

export default {
    name: "MergeDialogBox",
    mixins: [addl_profile_utilities, handles_grades, string_utilities],
    computed: {
        birth_date () {
            if(this.record && (this.record.birth_month !== null)) {
                const date = this.$moment(`2020-${this.record.birth_month}-01`) // use a leap year so Feb. 29th exists...
                return date.format("MMMM")
            }
            /*
            if(this.record && (this.record.birth_month !== null) && (this.record.birth_day !== null)) {
                const date = this.$moment(`2020-${this.record.birth_month}-${this.record.birth_day}`) // use a leap year so Feb. 29th exists...
                return date.format("MMMM Do")
            }
            */
            return ""
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        isSource: {
            type: Boolean,
            default: false
        }
    }
}
</script>
