<template>
    <data-table
        id="teachers-list"
        :columns="visible_columns"
        :rows="filtered_rows"
        :search="state.search"
        :total="state.total"
        :found="state.found"
        :current-page="state.page"
        :total-pages="nb_pages"
        :can-add="false"
        :clickable="false"
        :empty-title="$root.translate('No teachers at your institution')"
        class="teachers-list"
        @sort="sort_by"
        @search="search"
        @page="goto_page"
    >
        <template
            v-slot:summary="{ row }"
        >
            <h6>
                <img
                    v-if="!!row.image_url"
                    :src="row.image_url"
                    class="mr-2"
                />
                {{ row.name }}
            </h6>
        </template>
        <template
            v-slot:tbody="{ row, field }"
        >
            <div
                v-if="field === 'name'"
            >
                <img
                    v-if="!!row.image_url"
                    :src="row.image_url"
                    class="mr-2"
                />
                {{ row.name }}
            </div>
            <span v-else>{{ row[field] }}</span>
        </template>
    </data-table>
</template>

<script>
import { is_data_table_source } from "@/nibnut/mixins"

export default {
    name: "Teachers",
    mixins: [is_data_table_source],
    watch: {
        "records.length": "reset"
    },
    methods: {
    },
    computed: {
        state_identifier () {
            return "teachers-list"
        },
        rows () {
            return this.records
        }
    },
    props: {
        records: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" }
            },

            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>

<style lang="scss">
.teachers-list {
    table.table {
        img {
            max-height: 1em;
        }
    }
}
</style>
