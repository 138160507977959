<template>
    <page
        id="user-editor"
        :title="page_title"
        :back-navigation-fallback="default_back"
        :status="page_status"
        :waiting="waiting"
        :multi-boxes="true"
        @statused="statused"
    >
        <div class="card page-content-section">
            <div class="card-body">
                <div
                    v-if="is_student"
                    class="columns"
                >
                    <div class="column col-5 col-lg-6 col-sm-12">
                        <base-form
                            :has-required-fields="true"
                        >
                            <form-input
                                id="first_name"
                                name="first_name"
                                v-model="edited_record.first_name"
                                :required="true"
                                :editable="editable"
                                :saving="saving('first_name')"
                                :error="has_error('first_name')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("First Name") }}</template>
                            </form-input>

                            <form-input
                                id="last_name"
                                name="last_name"
                                v-model="edited_record.last_name"
                                :required="true"
                                :editable="editable"
                                :saving="saving('last_name')"
                                :error="has_error('last_name')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Last Name") }}</template>
                            </form-input>

                            <form-dropdown
                                id="grade"
                                name="grade"
                                v-model="edited_record.grade"
                                :options="raw_grades"
                                :required="true"
                                :editable="editable"
                                :saving="saving('grade')"
                                :error="has_error('grade')"
                                @input="save"
                            >
                                <template v-slot:label>{{ $root.translate("Grade") }}</template>
                            </form-dropdown>

                            <birth-month-day-input
                                :id="edited_record.id"
                                :record="edited_record"
                                :required="false"
                                :editable="editable"
                                :saving="saving('birth_month') || saving('birth_day')"
                                :error="has_error('birth_month') || has_error('birth_day')"
                                @input="save"
                            />
                        </base-form>
                    </div>
                    <div class="column col-7 col-lg-6 col-sm-12">
                        <student-activity-levels
                            :student="edited_record"
                            :editable="editable"
                        />
                    </div>
                </div>
                <div
                    v-else-if="!!edited_record"
                    class="columns"
                >
                    <div class="column col-3 col-lg-4 col-sm-12">
                        <div
                            class="nibnut-aspect-ratio-box nibnut-ratio-square"
                        >
                            <div>
                                <image-upload-input
                                    name="image_url"
                                    :url="edited_record.image_url || ''"
                                    :alt="edited_record.name"
                                    :accepts="upload_accepts('image_url')"
                                    :uploading="!!uploading.image_url"
                                    :uploaded="uploaded.image_url||0"
                                    :editable="editable"
                                    :error="has_error('image_url')"
                                    @upload="upload_file_list('image_url', $event)"
                                    @clear="confirm_clear_file('image_url')"
                                >
                                    <template v-slot:empty-title>
                                    {{ $root.translate("Drag or click here to set your profile image") }}
                                    </template>
                                    <template v-slot:empty-button-title>
                                        {{ $root.translate("Set profile image...") }}
                                    </template>
                                </image-upload-input>
                            </div>
                        </div>
                    </div>
                    <div class="column col-9 col-lg-8 col-sm-12">
                        <base-form
                            :has-required-fields="true"
                        >
                            <div class="columns">
                                <div class="column col-6 col-sm-12">
                                    <form-input
                                        id="first_name"
                                        name="first_name"
                                        v-model="edited_record.first_name"
                                        :required="true"
                                        :editable="editable"
                                        :saving="saving('first_name')"
                                        :error="has_error('first_name')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("First Name") }}</template>
                                    </form-input>
                                </div>
                                <div class="column col-6 col-sm-12">
                                    <form-input
                                        id="last_name"
                                        name="last_name"
                                        v-model="edited_record.last_name"
                                        :required="true"
                                        :editable="editable"
                                        :saving="saving('last_name')"
                                        :error="has_error('last_name')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Last Name") }}</template>
                                    </form-input>
                                </div>
                                <div class="column col-6 col-sm-12">
                                    <form-input
                                        id="email"
                                        name="email"
                                        type="email"
                                        v-model="edited_record.email"
                                        :required="true"
                                        :editable="editable"
                                        :saving="saving('email')"
                                        :error="has_error('email')"
                                        @input="save"
                                        class="mt-2"
                                    >
                                        <template v-slot:label>{{ $root.translate("Email") }}</template>
                                    </form-input>
                                </div>
                                <div class="column col-6 col-sm-12 d-flex-end">
                                    <feedback-button
                                        v-if="!me"
                                        :status="send_password_status"
                                        :waiting="sending_password"
                                        @click.prevent="send_reset_password"
                                        class="mt-2"
                                    >
                                        {{ $root.translate("Send a 'Reset Password' Link") }}
                                        <template v-slot:message>
                                            <div
                                                :class="{ 'text-success': (send_password_status === true), 'text-error': (send_password_status === false) }"
                                                class="text-center"
                                            >
                                                {{ send_password_message }}
                                            </div>
                                        </template>
                                    </feedback-button>
                                    <default-button
                                        v-else
                                        flavor="link"
                                        :block="true"
                                        @click.prevent="change_password"
                                    >
                                        {{ $root.translate("Change my password") }}
                                    </default-button>
                                </div>
                            </div>

                            <form-toggle-input
                                v-if="!!edited_record"
                                id="subscribed_to_newsletter"
                                name="subscribed_to_newsletter"
                                type="checkbox"
                                :value="edited_record.subscribed_to_newsletter"
                                :editable="editable"
                                :saving="saving('subscribed_to_newsletter')"
                                :error="has_error('subscribed_to_newsletter')"
                                class="mt-4"
                                @input="save"
                            >
                                {{ $root.translate("Subscribe to Newsletter") }}
                                <template v-slot:hint>{{ $root.translate("For members only, this email communication provides tutorials and how-to's to explore the platform. It's also the only way to be notified about new resources, technical difficulties and other important communications. It is highly recommended to signup for the duration of your subscription.") }}</template>
                            </form-toggle-input>

                            <div
                                class="text-center mt-8"
                            >
                                <default-button
                                    flavor="link"
                                    color="error"
                                    size="sm"
                                    @click.prevent="confirm_record_delete"
                                >
                                    {{ $root.translate("Delete Account") }}
                                </default-button>
                            </div>
                        </base-form>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="!!edited_record && !is_student"
            class="columns mb-4"
        >
            <div class="column col-6 col-sm-12">
                <div
                    class="card page-content-section full-height"
                >
                    <div class="card-header">
                        <div class="card-title h5">
                            {{ $root.translate("Address/Affiliation") }}
                            <help-button
                                v-if="editable && !is_at_least_administrator"
                                class="popover-right ml-2"
                            >
                                <template v-slot:label>
                                    {{ $root.translate("Why can't I change my affiliation?") }}
                                </template>
                                <p>{{ $root.translate("Please contact us to change your school.") }}</p>
                            </help-button>
                        </div>
                    </div>
                    <div class="card-body">
                        <form-select
                            id="institution_id"
                            name="institution_id"
                            v-model="edited_record.institution_id"
                            data-source="app"
                            :required="false"
                            :show-all="false"
                            :editable="editable && is_at_least_administrator"
                            :empty-label="$root.translate('Not affiliated to an institution')"
                            :saving="saving('institution_id')"
                            :error="has_error('institution_id')"
                            @input="update_address"
                        >
                            <template v-slot:label>{{ $root.translate("School or Institution") }}</template>
                        </form-select>

                        <address-input
                            id="address"
                            name="address"
                            :value="edited_record"
                            :fixed-country="true"
                            :required="false"
                            :editable="editable"
                            class="mt-4"
                            @input="save_address"
                        >
                            <template v-slot:label>{{ $root.translate("Billing Address") }}</template>
                            <template v-slot:fixed-country-message><span class="d-hidden"></span></template>
                        </address-input>

                        <form-toggle-input
                            v-if="!!edited_record.country && (edited_record.country !== 'CA')"
                            id="certified_at"
                            name="certified_at"
                            type="checkbox"
                            :value="!!edited_record.certified_at"
                            :editable="editable"
                            @input="edited_record.certified_at ? null : $moment()"
                        >
                            {{ $root.translate("I certify that I am not resident in Canada for purposes of the Excise Tax Act. Where applicable, I agree to keep my profile's address information up to date for purposes of the Excise Tax Act.") }}
                        </form-toggle-input>
                    </div>
                </div>
            </div>
            <div class="column col-6 col-sm-12">
                <div
                    class="card page-content-section full-height subscription-card"
                >
                    <div class="card-header">
                        <div class="card-title h5 columns">
                            <div class="column col-8 col-md-6 col-sm-12">
                                {{ $root.translate("Subscription") }}
                            </div>
                            <div class="column col-4 col-md-6 col-sm-12">
                                <form-input
                                    v-if="is_teacher && is_at_least_administrator"
                                    id="minimum_seats"
                                    name="minimum_seats"
                                    v-model="edited_record.minimum_seats"
                                    type="number"
                                    :min="1"
                                    :required="false"
                                    :saving="saving('minimum_seats')"
                                    :error="has_error('minimum_seats')"
                                    @input="save"
                                >
                                    <template v-slot:left_addon><span class="input-group-addon">{{ $root.translate("Min.") }}</span></template>
                                    <template v-slot:right_addon><span class="input-group-addon">{{ $root.translate("seats") }}</span></template>
                                </form-input>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div
                            v-if="!!license && !!license.id && !!license.plan_info"
                        >
                            <h3
                                v-if="!!license.computed_soft_max_students"
                            >
                                {{ license.computed_nb_students | nibnut.number("0,0") }}
                                &nbsp;/&nbsp;
                                <open-icon
                                    v-if="license.computed_soft_max_students === constants('unlimited_seats')"
                                    glyph="infinity"
                                />
                                <span v-else>
                                    {{ license.computed_soft_max_students | nibnut.number("0,0") }}
                                </span>
                                &nbsp;{{ $root.translate("students") }}
                                <help-button
                                    v-if="license.max_students !== license.computed_soft_max_students"
                                    flavor="normal"
                                    color="warning"
                                    glyph="exclamation-circle"
                                    class="popover-left ml-2"
                                >
                                    <p>{{ $root.translate("You have asked to renew your subscription at {new_max} students. Your current subscription allows you to have {max} students. You can add up to {add} students before renewal without any fees, but that will also increase the number of students your subscription renews at.", { new_max: license.computed_soft_max_students, max: license.max_students, add: license.max_students - license.computed_soft_max_students }) }}</p>
                                </help-button>
                            </h3>
                            <div
                                class="columns mb-2"
                            >
                                <div
                                    v-if="!!license.computed_valid && !!license.customer_info"
                                    class="column col-6 col-sm-12"
                                >
                                    <span v-if="!!license.customer_info.card_last_four">
                                        {{ $root.translate("Paid by {card_brand} ****{card_last_four} {card_exp_month}/{card_exp_year}", license.customer_info) }}
                                    </span>
                                    <span v-else class="text-gray">
                                        {{ $root.translate("No credit card on file") }}
                                    </span>
                                    <default-button
                                        v-if="editable && !license.standby_until"
                                        flavor="link"
                                        size="sm"
                                        :title="$root.translate('Add or change your credit card information')"
                                        @click.prevent="update_cc"
                                    >
                                        <open-icon glyph="pencil-alt" />
                                    </default-button>
                                </div>
                                <div
                                    v-if="!!license.gateway_data"
                                    class="column col-6 col-sm-12"
                                >
                                    {{ stripe_amount(license.gateway_data.last_payment.total, license.plan_info.interval, license.plan_info.interval_count, "") }} ({{ stripe_amount(plan_price(license.plan_info, license.coupon_info, []), license.plan_info.interval, license.plan_info.interval_count) }}<small class="text-gray">&nbsp;+&nbsp;{{ translate("tx") }}</small>)
                                    <default-button
                                        v-if="editable && !!license.computed_valid && !license.standby_until"
                                        flavor="link"
                                        size="sm"
                                        :title="$root.translate('Change your number of seats')"
                                        @click.prevent="subscribe"
                                    >
                                        <open-icon glyph="pencil-alt" />
                                    </default-button>
                                </div>
                            </div>
                            <div v-if="license.computed_valid" class="text-gray">
                                <span v-if="!!license.standby_until">
                                    {{ $root.translate("Canceled ; Will end on {date}", { date: nibnut_filter("nibnut.date", [license.standby_until, "ll"]) }) }}
                                </span>
                                <span v-else-if="!!license.gateway_data && !!license.gateway_data.next_payment && !!license.gateway_data.next_payment.at && !!license.gateway_data.next_payment.total">
                                    {{
                                        $root.translate("Next payment of {total} due on {date} ({students} students)", {
                                            date: nibnut_filter("nibnut.date", [license.gateway_data.next_payment.at, "lll"]),
                                            students: nibnut_filter("nibnut.number", [license.gateway_data.next_payment.quantity, "0,0"]),
                                            total: nibnut_filter("nibnut.currency", [license.gateway_data.next_payment.total / 100])
                                        })
                                    }}
                                </span>
                            </div>
                        </div>
                        <div
                            v-else
                        >
                            <h6 class="text-gray mb-4 text-center">{{ $root.translate("No Active Subscription") }}</h6>
                            <default-button
                                color="primary"
                                :block="true"
                                @click.prevent="subscribe"
                            >
                                {{ $root.translate("Subscribe...") }}
                            </default-button>
                        </div>
                    </div>
                    <div v-if="!!license && !!license.id" class="card-footer">
                        <default-button
                            v-if="!license.computed_valid"
                            color="primary"
                            :block="true"
                            @click.prevent="subscribe"
                        >
                            {{ $root.translate("Subscribe...") }}
                        </default-button>
                        <default-button
                            v-else-if="!!license.standby_until"
                            color="primary"
                            :block="true"
                            :waiting="modifying_subscription"
                            @click.prevent="resubscribe"
                        >
                            {{ $root.translate("Restart Subscription") }}
                        </default-button>
                       <default-button
                            v-else
                            flavor="link"
                            color="error"
                            size="sm"
                            :block="true"
                            :waiting="modifying_subscription"
                            @click.prevent="unsubscribe"
                        >
                            {{ $root.translate("Cancel Subscription") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </div>

        <a id="institutions-and-groups" class="nibnut-scroll-target"></a>
        <div
            v-if="is_student"
            class="card page-content-section"
        >
            <div class="card-header">
                <div class="card-title h5">
                    {{ $root.translate("Groups") }}
                </div>
            </div>
            <div class="card-body">
                <groups-list
                    :user="edited_record"
                />
            </div>
        </div>
        <div
            v-else-if="!!edited_record"
            class="columns"
        >
            <div
                v-if="!!edited_record.institution_id"
                class="column col-6 col-lg-12 mb-4"
            >
                <div
                    class="card page-content-section full-height"
                >
                    <div class="card-header">
                        <div class="card-title h5">
                            {{ $root.translate("Subscribed teachers at your institution") }}
                        </div>
                    </div>
                    <div class="card-body">
                        <teachers
                            :records="teachers"
                        />
                    </div>
                </div>
            </div>
            <div
                :class="{ 'col-6': !!edited_record.institution_id, 'col-12': !edited_record.institution_id }"
                class="column col-lg-12 mb-4"
            >
                <div
                    class="card page-content-section full-height"
                >
                    <div class="card-header">
                        <div class="card-title h5">
                            {{ $root.translate("Groups") }}
                        </div>
                    </div>
                    <div class="card-body">
                        <groups-list
                            :user="edited_record"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="!!edited_record && !!edited_record.id"
            class="card page-content-section"
        >
            <div class="card-header">
                <div class="card-title h5">
                    {{ $root.translate("Average accross all groups") }}
                </div>
            </div>
            <div class="card-body">
                <summary-graphs
                    entity="user"
                    :entity-id="edited_record.id || 0"
                />
            </div>
        </div>

        <div
            v-if="is_at_least_administrator"
            class="card page-content-section"
        >
            <div class="card-header">
                <div class="card-title h5">
                    {{ $root.translate("Admin Zone") }}
                </div>
            </div>
            <div v-if="!!edited_record" class="card-body">
                <div class="columns">
                    <div
                        class="column col-sm-12"
                    >
                        <form-select
                            id="role"
                            name="role"
                            v-model="edited_record.role"
                            :data-source="filtered_roles"
                            :required="true"
                            :editable="editable"
                            :saving="saving('role')"
                            :error="has_error('role')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Role") }}</template>
                        </form-select>
                    </div>
                    <div
                        v-if="!!edited_record&&!!edited_record.invitation_expires_at"
                        class="column col-sm-12 text-center"
                    >
                        <label
                            :class="{'text-error': invitation_expired, 'text-light-gray': !invitation_expired}"
                            class="form-label"
                        >
                            <span v-if="invitation_expired">{{ $root.translate("Invitation expired on") }}</span>
                            <span v-else>{{ $root.translate("Invitation valid until") }}</span>
                            {{ edited_record.invitation_expires_at | nibnut.date("YYYY-MM-DD [@] HH:mm") }}
                        </label>
                        <default-button
                            :title="$root.translate('Re-send invitation')"
                            :block="true"
                            @click.prevent="resend_invitation(edited_record)"
                        >
                            <open-icon glyph="share" /> {{ $root.translate('Re-send invitation') }}
                        </default-button>
                    </div>
                    <div
                        class="column col-sm-12"
                    >
                        <label
                            class="form-label d-invisible"
                        >
                            {{ $root.translate("Merge Accounts...") }}
                        </label>
                        <default-button
                            color="primary"
                            :block="true"
                            @click.prevent="merge"
                        >
                            {{ $root.translate("Merge Accounts...") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </div>

        <modal-dialog
            id="password-editor-dialog"
            :show.sync="changing_password"
        >
            <template v-slot:title><span class="h5">{{ $root.translate("Change my password") }}</span></template>
            <base-form
                :has-required-fields="true"
                @submit.prevent="save_password"
            >
                <password-editor
                    id="password-editor"
                    name="password"
                    v-model="new_password"
                    :required="true"
                >
                    <template v-slot:label>{{ $root.translate("Enter your new password") }}</template>
                </password-editor>
            </base-form>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="saving('password')"
                        class="mr-2"
                        @click.prevent="changing_password=false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        :waiting="saving('password')"
                        :disabled="saving('password')"
                        color="primary"
                        class="ml-2"
                        @click.prevent="save_password"
                    >
                        {{ $root.translate("Change password") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <reinvite-dialog
            :show.sync="reinviting"
            :user="reinvite"
        />

        <subscription-dialog
            v-if="!!edited_record && !!edited_record.id"
            :show.sync="editing"
            :edit-quantity="edit_quantity"
            :license="subscription"
            :user="edited_record"
        />

        <merge-dialog
            :show.sync="setting_up_merge"
            :record-id="edited_record ? edited_record.id : 0"
        />

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor, handles_grades, subscription_utilities } from "@/custom/mixins"
import { handles_uploads, is_user_inviter } from "@/nibnut/mixins"

import {
    BaseForm,
    FormInput,
    FormToggleInput,
    FormSelect,
    FormDropdown,
    AddressInput,
    FeedbackButton,
    DefaultButton,
    ModalDialog,
    PasswordEditor,
    ImageUploadInput,
    OpenIcon,
    HelpButton
} from "@/nibnut/components"
import {
    BirthMonthDayInput,
    GroupsList,
    SummaryGraphs
} from "@/custom/components"
import {
    SubscriptionDialog
} from "@/custom/dialogs"
import Teachers from "./Teachers"
import StudentActivityLevels from "./StudentActivityLevels"
import MergeDialog from "./MergeDialog"

export default {
    mixins: [is_record_editor, handles_grades, subscription_utilities, handles_uploads, is_user_inviter],
    components: {
        BaseForm,
        FormInput,
        FormToggleInput,
        FormSelect,
        FormDropdown,
        AddressInput,
        FeedbackButton,
        DefaultButton,
        ModalDialog,
        PasswordEditor,
        ImageUploadInput,
        OpenIcon,
        BirthMonthDayInput,
        GroupsList,
        SummaryGraphs,
        HelpButton,
        Teachers,
        StudentActivityLevels,
        SubscriptionDialog,
        MergeDialog
    },
    methods: {
        grab_record_id () {
            this.record_id = parseInt(this.$route.params.id) || this.profile_id
        },
        post_error (error) {
            if((error.number === 403) && (this.record_id !== this.profile_id)) this.$router.replace({ name: "profile" })
            else if(this.profile_id) this.$error(error.message)
        },
        send_reset_password () {
            this.sending_password = true
            this.$store.dispatch("SEND_PASSWORD_RESET", {
                email: this.edited_record.email
            }).then(data => {
                this.send_password_status = true
                this.send_password_message = data.message
            }).catch(error => {
                this.send_password_status = false
                this.send_password_message = error.message
            }).then(() => {
                this.sending_password = false
            })
        },
        change_password () {
            this.new_password = ""
            this.changing_password = true
        },
        save_password () {
            this.save(this.new_password, "password").then(() => {
                if(!this.has_error("password")) this.changing_password = false
            })
        },
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(png|jpe?g)$/i)
            return "image/jpeg,image/png,.jpg,.jpeg,.png"
        },
        upload_data (mode) {
            return {
                entity: this.entity,
                id: this.record_id
            }
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detached (name, response) {
            this.done_confirming()
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.$root.translate("Do you really want to delete this account? There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "clear-file") this.clear_file()
            else if(this.confirming === "delete-record") this.record_delete()
            else if(this.confirming === "unsubscribe") {
                this.modifying_subscription = true
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: "license",
                        id: this.license.id,
                        data: { relation_ids: ["user"], fields: ["ns::user;fieldset::default", "fieldset::billing"] },
                        unset: false
                    }
                ).then(record => {
                    this.done_confirming()
                }).catch(this.receive_error).then(() => {
                    this.modifying_subscription = false
                })
            } else this.done_confirming()
        },
        record_deleted () {
            if(this.record_id === this.profile_id) this.$router.push({ name: "dashboard" })
            else this.$router.push({ name: "user.list" })
        },
        update_address (institution_id, field, school) {
            this.edited_record.institution_id = parseInt(institution_id) || 0
            if(school.name && (!this.selected_school || (this.address_one_line(this.selected_school) === this.address_one_line(this.edited_record)))) {
                this.edited_record.line1 = school.line1
                this.edited_record.line2 = school.line2
                this.edited_record.city = school.city
                this.edited_record.state = school.state
                this.edited_record.country = school.country
                this.edited_record.zip = school.zip
            }
            this.selected_school = school
            this.save_address(this.edited_record)
        },
        save_address (data) {
            this.save_data_for_record_id(
                this.entity,
                this.edited_record.id,
                {
                    institution_id: data.institution_id,
                    line1: data.line1,
                    line2: data.line2,
                    city: data.city,
                    state: data.state,
                    country: data.country,
                    zip: data.zip
                }
            )
        },
        subscribe () {
            const edit = (subscription) => {
                this.subscription = subscription
                this.edit_quantity = true
                this.editing = true
            }
            const license = this.license
            if(!!license && !!license.id) edit(license)
            else {
                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    {
                        entity: "license",
                        data: { user_id: this.profile_id },
                        reset: true
                    }
                ).then(record => {
                    edit(record)
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        },
        update_cc () {
            this.subscription = this.license
            this.edit_quantity = false
            this.editing = true
        },
        unsubscribe () {
            const date = this.nibnut_filter("nibnut.date", [this.license.gateway_data.next_payment.at, "MMM. Do, YYYY"])
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Cancel Subscription"),
                    message: this.$root.translate("Are you sure you want to cancel your subscription? After {date}, you will lose all access to your student's information, and they may lose access to Sam Amuse.", { date }),
                    cancel: this.$root.translate("Keep Subscription"),
                    ok: this.$root.translate("Cancel Subscription")
                },
                "unsubscribe"
            )
        },
        resubscribe () {
            this.modifying_subscription = true
            this.$store.dispatch(
                "RECORD_RESTORE",
                {
                    entity: "license",
                    id: this.license.id,
                    data: { relation_ids: ["user"], fields: ["ns::user;fieldset::default", "fieldset::billing"] }
                }
            ).then(record => {
                this.done_confirming()
            }).catch(this.receive_error).then(() => {
                this.modifying_subscription = false
            })
        },
        merge () {
            this.setting_up_merge = true
        }
    },
    computed: {
        me () {
            const record = this.edited_record
            return !!this.profile_id && !!record && (this.profile_id === record.id)
        },
        roles () {
            return Object.values(this.constants("roles"))
        },
        filtered_roles () {
            if(!this.is_developer) return this.roles.filter(role => role.id !== this.constants("roles", "ROLE_DEVELOPER").id)
            return this.roles
        },
        license () {
            if(!this.edited_record || !this.edited_record.id) return {}
            return this.entity_records("license").find(license => license.user_id === this.edited_record.id) || {}
        },
        editable () {
            return !!this.edited_record && !!this.edited_record.id && !!this.profile_id && (this.is_at_least_administrator || (this.edited_record.id === this.profile_id))
        },
        is_student () {
            return !!this.edited_record && !!this.edited_record.id && (this.edited_record.role === this.constants("roles", "ROLE_STUDENT").id)
        },
        is_teacher () {
            return !!this.edited_record && !!this.edited_record.id && (this.edited_record.role === this.constants("roles", "ROLE_TEACHER").id)
        },
        invitation_expired () {
            const record = this.edited_record
            if(!!record && !!record.invitation_expires_at) return this.$moment(record.invitation_expires_at).isBefore()
            return false
        },
        default_back () {
            if(this.is_at_least_administrator) return { title: this.$root.translate(this.entityName, {}, 2), href: { name: "user.list" } }
            return { title: this.$root.translate("Dashboard"), href: { name: "home" } }
        },
        teachers () {
            if(!this.edited_record || !this.edited_record.id || !this.edited_record.institution_id) return []
            return this.entity_records("user").filter(user => {
                return (user.id !== this.edited_record.id) && (this.edited_record.institution_id === user.institution_id)
            })
        }
    },
    data () {
        return {
            entity: "user",
            entityName: "User:::Users",
            relation_ids: ["user", "group_user", "license"],
            fields: ["fieldset::default", "ns::user;fieldset::sublist", "ns::license;fieldset::billing"],

            changing_password: false,
            new_password: "",

            sending_password: false,
            send_password_status: null,
            send_password_message: "",

            subscription: {},
            edit_quantity: false,
            editing: false,
            modifying_subscription: false,

            setting_up_merge: false,
            selected_school: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#user-editor {
    .d-flex-end {
        display: flex;
        align-items: flex-end;
    }
    .full-height {
        height: 100%;
    }
    .card.subscription-card {
        & > .card-body > div {
            position: relative;
            transform: translate(0, -50%);
            top: 50%;

            h3 {
                padding: $unit-4;
                margin-bottom: $unit-8;
                border-top: 1px solid $border-color;
                border-bottom: 1px solid $border-color;
                text-align: center;

                .lar, .las, .lab {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
</style>
