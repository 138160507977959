<template>
    <div class="card text-center">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("No Student Seats Left") }}</div>
            <div v-if="!!message" class="card-subtitle">{{ message }}</div>
        </div>
        <div class="card-body">
            <subscription-pricing-table
                :applied-coupon-code="coupon_code"
                :min-seats="minimum_seats"
                :billing-country="profile.country"
                :billing-province="profile.state"
                :current-unit-price="current_unit_price"
                :payment-info="profile_license.gateway_data"
                :soft-maximum="profile_license.computed_soft_max_students"
                :hard-maximum="profile_license.max_students"
                @input="quantity = $event"
                @change="fee_change = $event"
            />
        </div>
        <div class="card-footer">
            <default-button
                :disabled="saving"
                class="mx-2"
                @click.prevent="$emit('cancel')"
            >
                {{ $root.translate("Cancel") }}
            </default-button>
            <default-button
                color="primary"
                :disabled="saving"
                :waiting="saving"
                class="mx-2"
                @click.prevent="pay"
            >
                {{ $root.translate("Upgrade") }}
            </default-button>
        </div>
    </div>
</template>

<script>
import { addl_profile_utilities, handles_payments } from "@/custom/mixins"

import {
    DefaultButton
} from "@/nibnut/components"

export default {
    name: "Upsell",
    mixins: [addl_profile_utilities, handles_payments],
    components: {
        DefaultButton
    },
    mounted () {
        this.setup_taxes()
    },
    watch: {
        "profile.country": "setup_taxes",
        "profile.province": "setup_taxes"
    },
    methods: {
        setup_taxes () {
            if(this.profile) {
                this.country = this.profile.country
                this.province = this.profile.province
            }
        },
        pay () {
            if(!this.quantity) return
            this.saving = true
            const license = this.profile_license
            const card_last_four = (!!license && !!license.customer_info) ? license.customer_info.card_last_four : null
            this.tokenize_payment(null, card_last_four).then(({ gateway_token }) => {
                const data = {
                    max_students: this.quantity,
                    relation_ids: ["user"],
                    fields: ["ns::user;fieldset::default", "fieldset::billing"]
                }
                this.$store.dispatch("RECORD_SAVE", {
                    entity: "license",
                    id: license.id,
                    data
                }).then(() => {
                    this.$emit("upsold")
                }).catch(error => {
                    this.$error(error)
                }).then(() => {
                    this.saving = false
                })
            }).catch(error => {
                this.$error(error.message)
                this.saving = false
            })
        }
    },
    computed: {
        minimum_seats () {
            return Math.max(this.profile.minimum_seats, this.profile_license.computed_nb_students)
        },
        current_unit_price () {
            return this.plan_price(this.profile_license.plan_info, this.profile_license.coupon_info, [])
        }
    },
    props: {
        message: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            saving: false,
            quantity: 0
        }
    }
}
</script>
