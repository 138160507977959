<template>
    <page
        id="student-profile-page"
        :title="$root.translate('{name}\'s Hall of Fame', { name: profile.first_name })"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <h2 v-if="!!profile" class="text-center">
                {{ $root.translate("{name}'s Hall of Fame", { name: profile.first_name }) }}
            </h2>
        </template>

        <award-badges
            :awards="awards"
        />
    </page>
</template>

<script>
import { profile_utilities } from "@/nibnut/mixins"
import { is_page, is_record_editor } from "@/custom/mixins"

import { AwardBadges } from "@/custom/components"

export default {
    name: "StudentProfile",
    mixins: [is_page, is_record_editor, profile_utilities],
    components: {
        AwardBadges
    },
    methods: {
        grab_record_id () {
            this.record_id = this.profile_id
        }
    },
    computed: {
        awards () {
            return this.entity_records("award")
        }
    },
    data () {
        return {
            entity: "user",
            entityName: "Student:::Students",
            relation_ids: ["award"],
            fields: ["fieldset::student-editor", "ns::award;fieldset::student-editor"]
        }
    }
}
</script>
