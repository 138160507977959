<template>
    <modal-dialog
        id="merge-dialog"
        :show.sync="shown"
        :dismissable="false"
        class="modal-lg"
    >
        <template v-slot:title>
            <div class="h5">
                {{ $root.translate("Merge Accounts") }}
            </div>
        </template>
        <p>
            {{ $root.translate("Merge account 'B' into account 'A':") }}
            <ul class="text-small">
                <li>{{ $root.translate("all the user information (name, email, password, birth date, ...) from account 'B' will be discarded") }}</li>
                <li>{{ $root.translate("all the user associations from account 'B' (assignments, group memberships, ...) will be transferred to account 'A'") }}</li>
            </ul>
        </p>

        <div v-if="!!record && !!record.id" class="merge-boxes">
            <div class="card merge-box">
                <div class="card-header">
                    <div class="card-title h5">
                        {{ record.name }}
                        <div class="account-indicator">A</div>
                    </div>
                </div>
                <div class="card-body">
                    <merge-dialog-box
                        :record="record"
                    />
                </div>
            </div>
            <div class="merge-icon-container">
                <open-icon
                    glyph="arrow-circle-left"
                    size="2x"
                    :class="{ 'text-gray': !source_id }"
                />
            </div>
            <div class="card merge-box">
                <div class="card-header">
                    <div class="card-title h5">
                        <div class="account-indicator">B</div>
                        <form-select
                            id="merge-source"
                            name="source_id"
                            :value="source_id"
                            data-source="user"
                            :context-id="record.id"
                            :data-source-additional-data="{ role: record.role, fields: ['fieldset::stitch'] }"
                            :empty-value="0"
                            :empty-label="$root.translate('User name, email or username')"
                            :required="true"
                            :show-all="false"
                            @input="change_source_id"
                        >
                            <template v-slot:option="{ option }">
                                {{ option.name }} <span class="text-small text-gray">{{ option.institution_name }}</span>
                            </template>
                        </form-select>
                    </div>
                </div>
                <div class="card-body">
                    <merge-dialog-box
                        v-if="!!source && !!source.id"
                        :record="source"
                        :is-source="true"
                    />
                    <div
                        v-else
                        class="empty"
                    >
                        <p class="empty-title h5">{{ $root.translate("Lookup another account by name, email or username in the search box above.") }}</p>
                    </div>
                </div>
            </div>
        </div>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    :disabled="!!saving"
                    class="mx-2"
                    @click.prevent="shown = false"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    color="primary"
                    :waiting="!!saving"
                    :disabled="!!saving || !source_id"
                    class="mx-2"
                    @click.prevent="merge"
                >
                    {{ $root.translate("Merge Accounts") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import { mapGetters } from "vuex"

import { confirms } from "@/nibnut/mixins"

import { ModalDialog, FormSelect, DefaultButton, OpenIcon } from "@/nibnut/components"
import MergeDialogBox from "./MergeDialogBox"

export default {
    name: "MergeDialog",
    mixins: [confirms],
    components: {
        ModalDialog,
        FormSelect,
        DefaultButton,
        OpenIcon,
        MergeDialogBox
    },
    mounted () {
        this.reset()
    },
    watch: {
        recordId: "reset",
        show: "reset"
    },
    methods: {
        reset () {
            this.source_id = 0
            if(this.recordId) {
                this.$store.dispatch(
                    "FETCH_RECORD",
                    {
                        entity: "user",
                        id: this.recordId,
                        query: {
                            fields: ["fieldset::stitch"]
                        }
                    }
                ).catch(error => {
                    this.$error(error.name)
                })
            }
        },
        change_source_id (source_id, noop, source) {
            this.source = source
            this.source_id = source_id
        },
        merge () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Merge {source_name} (B) into {name} (A)", { source_name: this.source.name, name: this.record.name }),
                    message: this.$root.translate("Do you really want to merge these two accounts? All assignments, group memeberships, ... from account 'B' will be transferred to account 'A'. All other data in account 'B' (name, email, password, birth date, ...) will be deleted. There is no undoing this operation!"),
                    cancel: this.$root.translate("Cancel"),
                    ok: this.$root.translate("Merge")
                },
                "merge"
            )
        },
        confirmed () {
            this.done_confirming()

            this.saving = true
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "user",
                    id: this.recordId,
                    action: `stitch/${this.source_id}`,
                    data: {
                        relation_ids: ["user", "group_user", "license"],
                        fields: ["fieldset::default", "ns::user;fieldset::sublist", "ns::license;fieldset::billing"]
                    },
                    method: "put"
                }
            ).then(() => {
                this.shown = false
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.saving = false
            })
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        record () {
            return this.entity_record("user", this.recordId)
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        recordId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            source_id: 0,
            source: {},
            saving: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#merge-dialog {
    p > ul {
        list-style: disc inside;
        margin: 0.8rem 0 0.8rem 0.8rem;
    }
    .merge-boxes {
        display: flex;
        align-items: center;

        & > .merge-box {
            flex: 1 1 50%;
        }
        & > .merge-icon-container {
            flex: 0 1 auto;
            margin: 0 $unit-4;
        }

        .card {
            .card-title {
                position: relative;

                .account-indicator {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    border: 3px solid $primary-color;
                    padding: $control-padding-y $control-padding-x;
                    border-radius: 50%;
                    line-height: 1em;
                }

                ul {
                    font-weight: normal;
                    font-size: 0.8rem;
                }
            }
            .card-body {
                position: relative;
                min-height: 14rem;

                & > .empty {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(0, -50%);
                }
            }

            .avatar {
                background: transparent;
            }

            &:last-child {
                .card-title {
                    text-align: right;
                    padding-left: 3em;

                    .account-indicator {
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
    }
}
</style>
