<template>
    <page
        id="users-list"
        :title="$root.translate('User:::Users', {}, 2)"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <data-table
            id="users-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="record_create(entity)"
            @click="record_edit(entity, $event.id)"
        >
            <template
                v-slot:controls
            >
                <div>
                    <div class="btn-group">
                        <default-button
                            :active="!state.filter_on || !state.filter"
                            @click.prevent="filter('role', null)"
                        >
                            {{ $root.translate('Any Role') }}
                        </default-button>
                        <default-button
                            v-for="role in filters"
                            :key="role.id"
                            :active="!!state.filter_on && (state.filter===role.id)"
                            @click.prevent="filter('role', role.id)"
                        >
                            {{ $root.translate(role.name) }}
                        </default-button>
                    </div>
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
                <p
                    v-if="row.role !== constants('roles', 'ROLE_STUDENT').id"
                    class="text-small"
                >
                    <mailto-link
                        :email="row.email"
                    >
                        {{ row.email }}
                    </mailto-link>
                </p>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'name'">
                    {{ row.name }}
                    <div v-if="!!row.invitation_expires_at" class="text-gray text-small">
                        <span v-if="invitation_expired(row)">{{ $root.translate("Invitation expired on") }}</span>
                        <span v-else>{{ $root.translate("Invitation valid until") }}</span>
                        {{ row.invitation_expires_at | nibnut.date("YYYY-MM-DD [@] HH:mm") }}
                        <default-button
                            :title="$root.translate('Re-send invitation')"
                            flavor="link"
                            size="sm"
                            @click.prevent.stop="resend_invitation(row)"
                        >
                            <open-icon glyph="share" />
                        </default-button>
                    </div>
                </div>
                <span v-else-if="field === 'email'">
                    <mailto-link
                        v-if="row.role !== constants('roles', 'ROLE_STUDENT').id"
                        :email="row.email"
                    >
                        {{ row.email }}
                    </mailto-link>
                </span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <reinvite-dialog
            :show.sync="reinviting"
            :user="reinvite"
        />

        <modal-dialog
            id="invite-user"
            :dismissable="!sending_invitation"
            :show.sync="inviting"
            class="modal-lg"
        >
            <template v-slot:title><span class="h5">{{ $root.translate("Invite User") }}</span></template>
            <base-form
                :has-required-fields="true"
                @submit.prevent="send_invitation"
            >
                <div class="columns">
                    <div class="column col-6 col-md-12 mb-2">
                        <form-input
                            id="email"
                            type="email"
                            name="email"
                            v-model="invitee.email"
                            :required="true"
                            :error="has_error('email')"
                        >
                            <template v-slot:label>{{ $root.translate("Email") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-md-12 mb-2">
                        <form-select
                            id="role"
                            name="role"
                            v-model="invitee.role"
                            :data-source="filtered_roles"
                            :required="true"
                            :error="has_error('role')"
                        >
                            <template v-slot:label>{{ $root.translate("Role") }}</template>
                        </form-select>
                    </div>
                </div>

                <div class="columns">
                    <div class="column col-6 col-lg-12 mb-2">
                        <form-input
                            id="first_name"
                            name="first_name"
                            v-model="invitee.first_name"
                            :required="false"
                            :error="has_error('first_name')"
                        >
                            <template v-slot:label>{{ $root.translate("First Name") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-lg-12 mb-2">
                        <form-input
                            id="last_name"
                            name="last_name"
                            v-model="invitee.last_name"
                            :required="false"
                            :error="has_error('last_name')"
                        >
                            <template v-slot:label>{{ $root.translate("Last Name") }}</template>
                        </form-input>
                    </div>
                </div>

                <form-select
                    v-if="!role_is_at_least_administrator(invitee.role)"
                    id="institution_id"
                    name="institution_id"
                    v-model="invitee.institution_id"
                    data-source="app"
                    :required="false"
                    :show-all="false"
                    @input="update_address"
                >
                    <template v-slot:label>{{ $root.translate("School or Institution") }}</template>
                </form-select>

                <address-input
                    v-if="!role_is_at_least_administrator(invitee.role)"
                    id="address"
                    name="address"
                    v-model="invitee"
                    :required="false"
                    :editable="!invitee.institution_id"
                    class="mt-4"
                >
                    <template v-slot:label>{{ $root.translate("Address") }}</template>
                </address-input>

                <h6 class="mt-4">{{ $root.translate("Subscription") }}</h6>
                <div class="columns">
                    <div class="column col-6 col-md-12 mb-2">
                        <form-input
                            id="minimum_seats"
                            type="number"
                            name="minimum_seats"
                            v-model="invitee.minimum_seats"
                            :min="1"
                            :required="true"
                            :error="has_error('minimum_seats')"
                        >
                            <template v-slot:label>{{ $root.translate("Minimum Seats") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-md-12 mb-2">
                        <form-dropdown
                            id="coupon_id"
                            name="coupon_id"
                            v-model="invitee.coupon_id"
                            :options="coupons"
                            :empty-value="0"
                            :empty-label="translate('No Coupon')"
                            :required="false"
                            :error="has_error('coupon_id')"
                        >
                            <template v-slot:label>{{ $root.translate("Coupon") }}</template>
                            <template v-slot:hint v-if="!!setting('auto_apply_coupon_code')">{{ $root.translate("Auto-Applied Coupon in effect; this will override it.") }}</template>
                        </form-dropdown>
                    </div>
                </div>

                <invitation-expiries
                    id="expires_in"
                    name="expires_in"
                    v-model="invitee.expires_in"
                    :required="true"
                    :error="has_error('expires_in')"
                />
            </base-form>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :waiting="sending_invitation"
                        :disabled="sending_invitation"
                        color="primary"
                        @click.prevent="send_invitation"
                    >
                        {{ $root.translate("Send Invitation") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </page>
</template>

<script type="text/javascript">
import orderBy from "lodash/orderBy"
import { mapState } from "vuex"

import { is_page } from "@/custom/mixins"
import { is_remote_data_table_source, crud_utilities, is_user_inviter } from "@/nibnut/mixins"

import {
    DefaultButton,
    MailtoLink,
    ModalDialog,
    BaseForm,
    FormInput,
    FormSelect,
    FormDropdown,
    AddressInput,
    InvitationExpiries,
    OpenIcon
} from "@/nibnut/components"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities, is_user_inviter],
    components: {
        DefaultButton,
        MailtoLink,
        ModalDialog,
        BaseForm,
        FormInput,
        FormSelect,
        FormDropdown,
        AddressInput,
        InvitationExpiries,
        OpenIcon
    },
    methods: {
        update_address (institution_id, field, school) {
            this.invitee.institution_id = parseInt(institution_id) || 0
            if(school.name) {
                this.invitee.line1 = school.line1
                this.invitee.line2 = school.line2
                this.invitee.city = school.city
                this.invitee.state = school.state
                this.invitee.country = school.country
                this.invitee.zip = school.zip
            }
        },
        record_create (entity) {
            this.$store.dispatch(
                "FETCH_RECORDS",
                {
                    entity: "coupon",
                    query: {
                        filter_on: "status",
                        filter: "valid",
                        fields: ["fieldset::picker"]
                    }
                }
            ).catch(error => {
                console.error(error.message)
            })
            this.record_shell(entity).then(record => {
                this.invitee = {
                    ...record,
                    coupon_id: 0,
                    expires_in: this.app_context.settings.default_invitation_delay
                }
                this.inviting = true
            })
        },
        invitation_expired (row) {
            if(!!row && !!row.invitation_expires_at) return this.$moment(row.invitation_expires_at).isBefore()
            return false
        },
        send_invitation () {
            this.sending_invitation = true
            return this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data: this.invitee
            }).then(record => {
                this.inviting = false
                this.invitee = {}
                this.record_edit(this.entity, record.id)
            }).catch(this.receive_error).then(() => {
                this.sending_invitation = false
            })
        }
    },
    computed: {
        ...mapState(["app_context"]),
        fields () {
            return [
                ...Object.keys(this.columns),
                "role"
            ]
        },
        roles () {
            return Object.values(this.constants("roles"))
        },
        filters () {
            const developer_id = this.constants("roles", "ROLE_DEVELOPER").id
            return this.roles.filter(role => role.id < developer_id)
        },
        filtered_roles () {
            const student_id = this.constants("roles", "ROLE_STUDENT").id
            const developer_id = this.constants("roles", "ROLE_DEVELOPER").id
            return this.roles.filter(role => (role.id > student_id) && (role.id < developer_id))
        },
        coupons () {
            return orderBy(this.entity_records("coupon"), "name", "asc")
        }
    },
    data () {
        return {
            entity: "user",
            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                email: { label: this.translate("Email"), sort: null, type: "alpha" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            inviting: false,
            invitee: {},
            sending_invitation: false
        }
    }
}
</script>

<style lang="scss">
#users-list {
    .btn-group {
        flex-wrap: nowrap;
    }
}
</style>
