<template>
    <div>
        <h6>{{ $root.translate("Assignments") }}</h6>

        <data-table
            id="students-assignments-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="editable"
            :clickable="false"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="record_create"
            @click="record_edit"
            class="students-assignments-list"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.asset_name }}</h6>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div
                    v-if="field === 'asset_name'"
                >
                    {{ row.asset_name }}
                </div>
                <div
                    v-else-if="field === 'progress'"
                >
                    <activity-progress
                        :assignment="row"
                        :class="{ clickable: !!row.computed_last_seen_at }"
                        @click.native="replay(student, row)"
                    />
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <assign-dialog
            v-if="!!action_record"
            :assignment="action_record"
            :student="student"
            :user="user"
            :show.sync="assigning"
            :fields="['fieldset::student-editor']"
            @created="refresh"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import { is_data_table_source } from "@/nibnut/mixins"

import { ActivityProgress } from "@/custom/components"
import { AssignDialog } from "@/custom/dialogs"

export default {
    name: "Assignments",
    mixins: [is_data_table_source],
    components: {
        ActivityProgress,
        AssignDialog
    },
    watch: {
        "student.id": "reset"
    },
    methods: {
        record_create () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                {
                    entity: "assignment",
                    reset: true
                }
            ).then(record => {
                this.record_edit({
                    ...record,
                    group_user_id: this.student.id,
                    grade: this.user.grade
                })
            }).catch(error => {
                this.$error(error.message)
            })
        },
        record_edit (assignment) {
            this.action_record = assignment
            this.assigning = true
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        state_identifier () {
            return "students-assignments-list"
        },
        rows () {
            if(!this.student || !this.student.id) return []
            const type_id = this.constants("asset_types", "TYPE_GAME").id
            return this.entity_records("assignment").filter(assignment => {
                return (assignment.group_user_id === this.student.id) && (assignment.asset_type === type_id)
            })
        }
    },
    props: {
        student: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            columns: {
                asset_name: { label: this.translate("Activity"), sort: "asc", type: "alpha" },
                current_level: { label: this.translate("Level"), sort: null, type: "numeric" },
                progress: { label: this.translate("Progress"), sort: false, head_class: "text-right", cell_class: "text-right" }
            },

            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            action_record: null,
            assigning: false
        }
    }
}
</script>

<style lang="scss">
.students-assignments-list {
    .activity-progress-container {
        display: inline-block;
    }
}
</style>
