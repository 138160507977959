<template>
    <page
        id="student-editor"
        :title="page_title"
        :back-navigation-fallback="{ title: translate(entityName, {}, 2), href: { name: 'student.list' } }"
        :status="page_status"
        :waiting="waiting"
        :narrow="true"
        @statused="statused"
    >
        <upsell
            v-if="!!upsell"
            :message="upsell"
            @cancel="pop"
            @upsold="upsold"
        />
        <base-form
            v-else
            :has-required-fields="true"
            @submit="create"
        >
            <div class="card">
                <div v-if="!!edited_record" class="card-body">
                    <div
                        class="columns"
                    >
                        <div
                            class="column col-3 col-sm-12"
                        >
                            <avatar-input
                                id="avatar_id"
                                name="avatar_id"
                                v-model="edited_record.avatar_id"
                                :editable="editable"
                                :error="has_error('avatar_id')"
                                @input="save"
                            />
                            <default-button
                                v-if="editable"
                                flavor="link"
                                size="sm"
                                :title="translate('Regenerate avatar')"
                                :block="true"
                                class="mt-2"
                                @click.prevent="regenerate('avatar_id')"
                            >
                                <open-icon
                                    glyph="sync"
                                    :title="translate('Regenerate avatar')"
                                    :spin="!!generating.avatar_id"
                                />
                                {{ translate("Regenerate avatar") }}
                            </default-button>

                            <base-link
                                v-if="!!edited_record && !!edited_record.id"
                                :href="{ name: 'group.access-cards', params: { id: edited_record.group_id }, query: { student_id: edited_record.id } }"
                                target="_blank"
                                :title="translate('Print Access Card')"
                                class="btn btn-primary btn-block my-8"
                                @click.prevent
                            >
                                <open-icon glyph="id-card" :title="translate('Print Access Card')" class="mr-2" />
                                {{ translate("Print Access Card") }}
                            </base-link>

                            <default-button
                                v-if="editable && !!edited_record.id"
                                flavor="link"
                                color="error"
                                size="sm"
                                :block="true"
                                @click.prevent="confirm_record_delete"
                            >
                                {{ translate("Archive Student") }}
                            </default-button>
                        </div>
                        <div
                            class="column col-9 col-sm-12"
                        >
                            <form-select
                                v-if="!editable || (profile_editable_groups.length > 1)"
                                id="group_id"
                                name="group_id"
                                v-model="edited_record.group_id"
                                data-source="group"
                                :required="true"
                                :ad-hoc="false"
                                :show-all="!is_at_least_administrator"
                                :empty-label="translate('Student\'s group...')"
                                :editable="editable"
                                :saving="saving('group_id')"
                                :error="has_error('group_id')"
                                class="mb-4"
                                @loaded="current_group = $event"
                                @input="save_group"
                            />

                            <div
                                v-if="!editing_user && !edited_record.id"
                                class="user-info-container"
                            >
                                <form-select
                                    id="user_id"
                                    name="user_id"
                                    v-model="edited_record.user_id"
                                    data-source="user"
                                    :stored-data-source="true"
                                    :data-source-additional-data="{ institution_id: null, fields: ['fieldset::group_user'] }"
                                    :required="true"
                                    :ad-hoc="true"
                                    :show-all="false"
                                    :empty-label="translate('Student\'s name...')"
                                    label-field="display_name"
                                    :ad-hoc-choice-prefix="`${translate('Create')} `"
                                    :editable="editable"
                                    :saving="saving('user_id')"
                                    :error="has_error('user_id') || (!editing_user && (has_error('first_name') || has_error('last_name')))"
                                    class="mb-4"
                                    @input="user_save"
                                    @create="user_create"
                                >
                                    <template v-slot:option="{ option }">
                                        {{ option.display_name }}
                                    </template>
                                </form-select>
                                <default-button
                                    v-if="editable && !!edited_record.user_id"
                                    class="ml-2"
                                    @click.prevent="editing_user = true"
                                >
                                    <open-icon glyph="pen" />
                                </default-button>
                            </div>
                            <div
                                v-else
                                class="user-info-container"
                            >
                                <div class="columns">
                                    <div class="column col-6 col-sm-12 mb-4">
                                        <form-input
                                            id="first_name"
                                            name="first_name"
                                            v-model="edited_user.first_name"
                                            :required="true"
                                            :editable="editable"
                                            :saving="saving('first_name')"
                                            :error="has_error('first_name')"
                                            @input="user_save"
                                        >
                                            <template v-slot:label>{{ translate("First Name") }}</template>
                                        </form-input>
                                    </div>
                                    <div class="column col-6 col-sm-12 mb-4">
                                        <form-input
                                            id="last_name"
                                            name="last_name"
                                            v-model="edited_user.last_name"
                                            :required="true"
                                            :editable="editable"
                                            :saving="saving('first_name')"
                                            :error="has_error('first_name')"
                                            @input="user_save"
                                        >
                                            <template v-slot:label>{{ translate("Last Name") }}</template>
                                        </form-input>
                                    </div>

                                    <div
                                        v-if="is_at_least_administrator"
                                        class="column col-4 mb-4"
                                    >
                                        <form-dropdown
                                            id="grade"
                                            name="grade"
                                            v-model="edited_user.grade"
                                            :options="raw_grades"
                                            :required="true"
                                            :editable="editable"
                                            :saving="saving('grade')"
                                            :error="has_error('grade')"
                                            @input="user_save"
                                        >
                                            <template v-slot:label>{{ translate("Grade") }}</template>
                                        </form-dropdown>
                                    </div>
                                    <div
                                        :class="{ 'col-8': is_at_least_administrator, 'col-12': !is_at_least_administrator }"
                                        class="column mb-4"
                                    >
                                        <birth-month-day-input
                                            :id="edited_user.id"
                                            :record="edited_user"
                                            :required="false"
                                            :editable="editable"
                                            :saving="saving('birth_month') || saving('birth_day')"
                                            :error="has_error('birth_month') || has_error('birth_day')"
                                            @input="user_save"
                                        />
                                    </div>
                                </div>
                                <default-button
                                    v-if="!!edited_record && !edited_record.id"
                                    class="ml-2 with-margin"
                                    @click.prevent="editing_user = false"
                                >
                                    <open-icon :glyph="edited_record.user_id ? 'check' : 'times'" />
                                </default-button>
                            </div>
                            <div class="columns">
                                <div
                                    :class="{ 'col-6': requires_password && current_group && (current_group.grade >= setting('min_grade_for_text_password')), 'col-12': !requires_password || !current_group || (current_group.grade >= setting('min_grade_for_image_password')) }"
                                    class="column col-sm-12"
                                >
                                    <form-input
                                        id="username"
                                        name="username"
                                        v-model="edited_record.username"
                                        :required="false"
                                        :editable="editable"
                                        :saving="saving('username')"
                                        :error="has_error('username')"
                                        @input="save"
                                    >
                                        <template v-slot:label>
                                            {{ translate("Username") }}
                                            <default-button
                                                v-if="editable"
                                                flavor="link"
                                                size="sm"
                                                :title="translate('Regenerate username')"
                                                @click.prevent="regenerate('username')"
                                            >
                                                <open-icon
                                                    glyph="sync"
                                                    :title="translate('Regenerate username')"
                                                    :spin="!!generating.username"
                                                />
                                            </default-button>
                                        </template>
                                    </form-input>
                                </div>
                                <div
                                    v-if="requires_password && current_group && (current_group.grade >= setting('min_grade_for_text_password'))"
                                    class="column col-6 col-sm-12"
                                >
                                    <password-input
                                        id="password"
                                        name="password"
                                        v-model="edited_record.password"
                                        :required="true"
                                        :editable="editable"
                                        :saving="saving('password')"
                                        :error="has_error('password')"
                                        @input="save"
                                    >
                                        <template v-slot:label>
                                            {{ translate("Password") }}
                                            <default-button
                                                v-if="editable"
                                                flavor="link"
                                                size="sm"
                                                :title="translate('Regenerate password')"
                                                @click.prevent="regenerate('password')"
                                            >
                                                <open-icon
                                                    glyph="sync"
                                                    :title="translate('Regenerate password')"
                                                    :spin="!!generating.password"
                                                />
                                            </default-button>
                                        </template>
                                    </password-input>
                                </div>
                                <div
                                    v-else-if="requires_password && current_group && (current_group.grade >= setting('min_grade_for_image_password'))"
                                    class="column col-12"
                                >
                                    <form-visual-password-input
                                        id="password"
                                        name="password"
                                        v-model="edited_record.password"
                                        :input-mode="false"
                                        :required="true"
                                        :error="has_error('password')"
                                        @input="save"
                                    >
                                        <template v-slot:label>
                                            {{ translate("Password") }}
                                            <default-button
                                                v-if="editable"
                                                flavor="link"
                                                size="sm"
                                                :title="translate('Regenerate password')"
                                                @click.prevent="regenerate('password')"
                                            >
                                                <open-icon
                                                    glyph="sync"
                                                    :title="translate('Regenerate password')"
                                                    :spin="!!generating.password"
                                                />
                                            </default-button>
                                        </template>
                                    </form-visual-password-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="!!edited_record && !edited_record.id"
                    class="card-footer text-center"
                >
                    <default-button
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="$router.go(-1)"
                    >
                        {{ translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="!!creating"
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="create"
                    >
                        {{ translate("Save") }}
                    </default-button>
                </div>
            </div>

            <div class="text-center text-gray my-8" v-html="student_info_blurb"></div>

            <div v-if="!!edited_record && !!edited_record.id" class="card">
                <div class="card-body">
                    <summary-graphs
                        entity="group_user"
                        :entity-id="edited_record.id || 0"
                    />

                    <div
                        v-if="awards.length"
                        class="text-center"
                    >
                        <h6>{{ translate("Awarded Badges") }}</h6>

                        <award-badges
                            :awards="awards"
                            size="sm"
                        />
                    </div>
                </div>
            </div>

            <div v-if="!!edited_record && !!edited_record.id && !!edited_user" class="card mt-8">
                <div class="card-body">
                    <div class="columns">
                        <student-activity-levels
                            class="column col-4 col-md-5 col-sm-12"
                            :student="edited_record"
                            :editable="editable && is_at_least_administrator"
                        />
                        <assignments
                            class="column col-8 col-md-7 col-sm-12"
                            :student="edited_record"
                            :user="edited_user"
                            :editable="editable"
                        />
                    </div>
                </div>
            </div>
        </base-form>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor, handles_grades } from "@/custom/mixins"

import {
    BaseForm,
    FormInput,
    FormDropdown,
    FormSelect,
    PasswordInput,
    DefaultButton,
    OpenIcon,
    BaseLink
} from "@/nibnut/components"
import {
    AvatarInput,
    FormVisualPasswordInput,
    BirthMonthDayInput,
    SummaryGraphs,
    AwardBadges
} from "@/custom/components"
import StudentActivityLevels from "./StudentActivityLevels"
import Upsell from "./Upsell"
import Assignments from "./Assignments"

export default {
    mixins: [is_record_editor, handles_grades],
    components: {
        BaseForm,
        FormInput,
        FormDropdown,
        FormSelect,
        PasswordInput,
        FormVisualPasswordInput,
        BirthMonthDayInput,
        SummaryGraphs,
        AwardBadges,
        DefaultButton,
        OpenIcon,
        BaseLink,
        AvatarInput,
        Upsell,
        StudentActivityLevels,
        Assignments
    },
    mounted () {
        this.maybe_init_current_group()
    },
    watch: {
        profile_id: "maybe_init_current_group"
    },
    methods: {
        maybe_init_current_group () {
            if(!this.current_group && (this.profile_editable_groups.length === 1)) {
                const group_user = this.profile_editable_groups[0]
                this.$store.dispatch(
                    "FETCH_RECORD",
                    {
                        entity: "group",
                        id: group_user.group_id,
                        reset: false
                    }
                ).then(group => {
                    this.current_group = group
                })
            }
        },
        post_load () {
            if(this.edited_record && !this.edited_record.id) {
                this.edited_record.role = this.constants("group_user_types", "ROLE_STUDENT").id
                if(this.$route.params.group_id) this.edited_record.group_id = this.$route.params.group_id
                else if(this.$route.query.group_id) this.edited_record.group_id = parseInt(this.$route.query.group_id)

                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    {
                        entity: "user",
                        data: {
                            grade: 0,
                            fields: ["fieldset::group_user"]
                        },
                        reset: true
                    }
                ).then(record => {
                    this.edited_user = record
                }).catch(error => {
                    this.$error(error.message)
                })
                this.refresh_shell()
            } else {
                this.edited_user = this.entity_record("user", this.edited_record.user_id) || {}
            }
            this.$store.dispatch(
                "FETCH_RECORD",
                {
                    entity: "group",
                    id: this.edited_record.group_id,
                    reset: false
                }
            ).then(group => {
                this.current_group = group
            })
        },
        refresh_shell () {
            if(this.edited_record && !this.edited_record.id) {
                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    {
                        entity: this.entity,
                        data: {
                            user_id: this.edited_record.user_id,
                            group_id: this.edited_record.group_id
                        },
                        reset: true
                    }
                ).then(record => {
                    if(!record.user_id) this.edited_record.user_id = record.user_id
                    if(!record.group_id) this.edited_record.group_id = record.group_id
                    this.edited_record.password = record.password
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        },
        save_group (value, field, option) {
            this.current_group = option
            this.save(value, field)
            this.refresh_shell()
        },
        maybe_set_username () {
            if(!this.edited_record.id) {
                const username = []
                if(this.edited_user.first_name) username.push(this.edited_user.first_name)
                if(this.edited_user.last_name) username.push(this.edited_user.last_name.substr(0, 1) + ".")
                this.save(username.join(" "), "username")
            }
        },
        user_create (name) {
            this.clear_all_errors()
            if(name) {
                const matches = name.match(/^([^\s]+)\s+(.+)$/)
                if(matches) {
                    this.edited_user.first_name = matches[1]
                    this.edited_user.last_name = matches[2]
                    if(this.edited_user.id) {
                        this.save_data_for_record_id(
                            "user",
                            this.edited_user.id,
                            {
                                first_name: this.edited_user.first_name,
                                last_name: this.edited_user.last_name
                            }
                        )
                    }
                } else this.user_save(name, "first_name")
                this.edited_user.grade = this.current_group ? this.current_group.grade : 0
                this.maybe_set_username()
                this.editing_user = true
            }
        },
        user_save (value, field) {
            if(this.edited_user) {
                if(this.edited_user[field] !== value) this.edited_user[field] = value
                if(field === "user_id") {
                    const student = this.entity_record("user", value)
                    if(student) {
                        this.edited_user.first_name = student.first_name
                        this.edited_user.last_name = student.last_name
                    }
                }
                if((field === "user_id") || (field === "last_name")) this.maybe_set_username()
                if(this.edited_user.id) return this.save_field_for_record_id("user", this.edited_user.id, this.edited_user[field], field)
            }
            return Promise.resolve()
        },
        create () {
            const { image_url, name, ...group_user } = this.edited_record
            const data = {
                ...group_user,
                ...this.edited_user,
                relation_ids: this.relation_ids
            }

            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: this.entity,
                    data
                }
            ).then(record => {
                this.editing_user = false
                this.$store.dispatch("HISTORY_POP", {})
                this.$router.replace({ name: "student.edit", params: { id: record.id } })
            }).catch(error => {
                if(error && ((error.number === 402) || (error.number === 418))) {
                    if(error.number === 402) this.upsell = error.message
                    else if(error.number === 418) this.$warn(error.message, 30000)
                } else this.receive_error(error)
            })
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Archive {name}", { name: this.edited_user.name }),
                    message: this.translate("Do you really want to archive this student? You will no longer have access to this student's data. There is no undo!"),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Archive")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.edited_record.id
                    }
                ).then(() => {
                    this.done_confirming()
                    this.$store.dispatch("HISTORY_POP", {})
                    this.$router.replace({ name: "student.list" })
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        },
        regenerate (field) {
            this.generating[field] = true
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: this.entity,
                    id: this.edited_record.id,
                    action: `regenerate/${field}`,
                    passthru: !this.edited_record.id,
                    method: "post"
                }
            ).then(record => {
                if(!this.edited_record.id) this.edited_record[field] = record[field]
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.generating[field] = false
            })
        },
        pop () {
            this.$store.dispatch("HISTORY_POP", {})
            this.$router.go(-1)
            this.upsell = false
        },
        upsold () {
            this.upsell = false
            this.create()
        }
    },
    computed: {
        editable () {
            if(this.is_at_least_administrator) return true
            return (!!this.profile_editable_group_ids.length && !!this.current_group && (this.profile_editable_group_ids.indexOf(this.current_group.id) >= 0)) // !!this.profile_editable_groups.length
        },
        requires_password () {
            if(!!this.current_group && (this.current_group.grade < this.setting("min_grade_for_image_password"))) return false
            return true
        },
        awards () {
            if(!this.edited_record || !this.edited_record.id) return []
            return this.entity_records("award").filter(award => {
                return ((award.owner_type === "App\\User") && (award.owner_id === this.edited_record.user_id)) || ((award.owner_type === "App\\GroupUser") && (award.owner_id === this.edited_record.id))
            })
        },
        student_info_blurb () {
            const urls = this.setting("urls") || {}
            const policy_link = urls.privacy ? `<a href="${urls.privacy}">${this.translate("Privacy Policy")}</a>` : ""
            const faq_link = urls.faq ? `<a href="${urls.faq}">${this.translate("FAQ")}</a>` : ""

            if(!!policy_link && !!faq_link) return this.translate("Want to know why and how we use your student's information? Consult our {policy_link} and our {faq_link}.", { policy_link, faq_link })
            if(!faq_link) return this.translate("Want to know why and how we use your student's information? Consult our {policy_link}.", { policy_link })
            return this.translate("Want to know why and how we use your student's information? Consult our {faq_link}.", { faq_link })
        }
    },
    data () {
        return {
            entity: "group_user",
            entityName: "Student:::Students",
            relation_ids: ["user", "assignment", "subject_user", "award"],
            fields: ["fieldset::default", "ns::user;fieldset::student-editor", "ns::assignment;fieldset::student-editor", "ns::subject_user;fieldset::student-editor", "ns::award;fieldset::student-editor"],
            creating: false,

            current_group: null,
            editing_user: false,
            edited_user: {},

            generating: {
                avatar_id: false,
                username: false,
                password: false
            },

            upsell: false
        }
    }
}
</script>

<style lang="scss">
#student-editor {
    .user-info-container {
        display: flex;

        & > .form-group {
            flex: 1 0 auto;
        }
        & > div.columns {
            flex: 1 1 auto;
        }
        & > .btn {
            flex: 0 0 auto;

            &.with-margin {
                margin-top: 1.8rem;
            }
        }
    }
    .visual-password-input {
        .visual-password-token-containers {
            padding: 0 20%;
        }
        .visual-password-tokens {
            padding: 0 10%;
        }
    }
}
</style>
