<template>
    <div>
        <h6>{{ $root.translate("Abilities") }}</h6>

        <data-table
            id="students-activity-levels-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :clickable="false"
            :searchable="false"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ subject_for_row(row).name }}</h6>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div
                    v-if="field === 'name'"
                >
                    {{ subject_for_row(row).name }}
                </div>
                <form-dropdown
                    v-else-if="field === 'grade'"
                    :id="`grade-${row.id}`"
                    name="grade"
                    :value="row.grade"
                    :options="available_grades"
                    :required="true"
                    :editable="editable"
                    @input="save_subject_user(row, $event, 'grade')"
                />
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import { is_data_table_source, handles_saving } from "@/nibnut/mixins"
import { handles_grades } from "@/custom/mixins"

import { FormDropdown } from "@/nibnut/components"

export default {
    name: "StudentActivityLevels",
    mixins: [is_data_table_source, handles_saving, handles_grades],
    components: {
        FormDropdown
    },
    watch: {
        "student.id": "reset"
    },
    methods: {
        subject_for_row (row) {
            return this.entity_record("tag", row.subject_tag_id) || {}
        },
        save_subject_user (row, value, field) {
            this.save_data_for_record_id("subject_user", row.id, { [field]: value })
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        state_identifier () {
            return "students-activity-levels-list"
        },
        rows () {
            if(!this.student || !this.student.id) return []
            return this.entity_records("subject_user").filter(subject_user => subject_user.user_id === this.student.user_id)
        }
    },
    props: {
        student: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            columns: {
                name: { label: this.translate("Subject"), sort: "asc", type: "alpha" },
                grade: { label: this.translate("Level"), sort: null, type: "numeric" }
            },

            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
