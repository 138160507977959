<template>
    <page
        id="videos-page"
        :title="title"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <h2 v-if="!!profile" class="text-center">
                <span v-if="!rows.length">{{ $root.translate("Hello {name}!", { name: profile.first_name }) }}</span>
                <span v-else>{{ $root.translate("Hello {name}, here are some fun videos", { name: profile.first_name }) }}:</span>
            </h2>
        </template>

        <div v-if="!rows.length" class="empty">
            <div class="empty-icon">
                <open-icon glyph="sad-cry" size="3x" />
            </div>
            <p class="empty-title">{{ $root.translate("Aw shoot! You have no videos available") }}</p>
            <p class="empty-subtitle h5">{{ $root.translate("Ask your teacher to assign some to you.") }}</p>
        </div>
        <div v-else class="columns semi-responsive">
            <div
                v-for="assignment in rows"
                :key="assignment.id"
                class="column"
                @click.prevent="watch_video(asset_by_id(assignment.asset_id))"
            >
                <game-tile
                    :game="asset_by_id(assignment.asset_id)"
                />
            </div>
        </div>

        <video-player-dialog
            :show.sync="watching"
            :name="watching_name"
            :settings="watching_settings"
        />
    </page>
</template>

<script>
import { mapGetters } from "vuex"

import { is_remote_data_table_source, profile_utilities } from "@/nibnut/mixins"
import { is_page } from "@/custom/mixins"

import { OpenIcon } from "@/nibnut/components"
import { GameTile } from "@/custom/components"
import {
    VideoPlayerDialog
} from "@/custom/dialogs"

export default {
    name: "Videos",
    mixins: [is_page, is_remote_data_table_source, profile_utilities],
    components: {
        OpenIcon,
        GameTile,
        VideoPlayerDialog
    },
    methods: {
        pre_load () {
            this.set_state_value("filter", this.constants("asset_types", "TYPE_VIDEO").id)
        },
        asset_by_id (id) {
            return this.entity_record("asset", id) || {}
        },
        watch_video (video) {
            this.watching_name = video.name
            this.watching_settings = video.settings
            this.watching = true
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        title () {
            return this.$root.translate("Video")
        },
        fields () {
            return [
                "fieldset::student-list",
                "ns::asset;fieldset::student-list"
            ]
        }
    },
    data () {
        return {
            entity: "assignment",
            entityName: "Video:::Videos",
            relation_ids: ["asset"],

            default_state: {
                per_page: 0,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: "type",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            watching: false,
            watching_name: "",
            watching_settings: {}
        }
    }
}
</script>
