<template>
    <page
        id="students-list"
        :title="translate('Student:::Students', {}, 2)"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <base-students-list
        />
    </page>
</template>

<script>
import { is_page } from "@/custom/mixins"

import { StudentsList as BaseStudentsList } from "@/custom/components"

export default {
    name: "StudentsList",
    mixins: [is_page],
    components: {
        BaseStudentsList
    }
}
</script>
