<template>
    <page
        id="glossary-page"
        :title="title"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <div class="text-center">{{ title }}</div>
        </template>

        <glossary-terms
            v-if="!!asset && !!asset.id && !!profile_id"
            :glossary-id="asset.id"
        />
    </page>
</template>

<script>
import { mapGetters } from "vuex"

import { is_page, addl_profile_utilities } from "@/custom/mixins"

import {
    GlossaryTerms
} from "@/custom/components"

const is_slug = (id) => {
    return (typeof id === "string") && (id !== `${parseInt(id)}`)
}

export default {
    name: "Glossary",
    mixins: [is_page, addl_profile_utilities],
    components: {
        GlossaryTerms
    },
    watch: {
        $route: "load",
        profile_id: "load"
    },
    methods: {
        load () {
            if(!this.$route.params.id) this.statused(404)
            else if(this.profile_id) {
                this.$store.dispatch(
                    "FETCH_RECORD",
                    {
                        entity: "asset",
                        id: this.$route.params.id,
                        query: {
                            fields: ["fieldset::play"]
                        }
                    }
                ).catch(error => {
                    console.error(error.message)
                    this.statused(404)
                })
            }
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record", "history_back_info"]),
        asset () {
            if(is_slug(this.$route.params.id)) return this.entity_records("asset").find(asset => asset.slug === this.$route.params.id) || {}
            return this.entity_record("asset", this.$route.params.id) || {}
        },
        title () {
            const asset = this.asset
            if(asset) return asset.name
            return this.$root.translate("Another Sam Amuse Glossary")
        }
    }
}
</script>
